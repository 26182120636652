<template>
  <q-card class="card text-center" flat>
    <q-card-section>
      <img src="../assets/logo.svg" />
      <h4> {{title}} </h4>
    </q-card-section>
    <q-card-section>
      <form @submit.prevent="sendPasswordResetLink" v-if="!sent">
        <q-field class="email" icon="email" type="text" label="" helper=""  error-label="We need a valid email">
          <q-input v-model="identifier" label="Email or Mobile" outlined required="required">
            <template v-slot:prepend>
              <q-icon name="perm_identity" color="light-blue-5" />
            </template>
          </q-input>
        </q-field>
        <br>
        <br>
        <div class="row justify-start items-center">
          <q-btn class="submitLogin full-width text-white" rounded :disabled="processing" type="submit" size="lg">
            Send Reset Link
          </q-btn>
        </div>
      </form>
      <div v-else class="my-text">
        The password was sent to the {{method}} specified during registration.
      </div>
      <br>
      <br>
      <div class="row justify-center items-center">
          <span class="my-text">
            Back to <a id="registerLink" @click="navTo('login')">Sign In</a>
          </span>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { QInput, QField, QBtn, QCard, QCardSection, Notify } from 'quasar'
import { mapActions } from 'vuex'

export default {
  meta: {
    title: 'Password'
  },
  data () {
    return {
      processing: false,
      identifier: null,
      sent: false,
      method: null
    }
  },
  computed: {
    title () {
      return this.sent ? `Check your ${this.method}` : 'Forgot Password'
    }
  },
  methods: {
    async sendPasswordResetLink () {
      this.processing = true
      this.$q.loading.show({
        delay: 400
      })
      try {
        var { data } = await this.$axios.post('password/email', { identifier: this.identifier })
        this.method = data.method

        Notify.create({
          message: `a link for setting a password was sent to ${this.identifier} `,
          icon: 'done',
          timeout: 10000,
          color: 'positive',
          textcolor: '#fff'
        })
        this.sent = true
      } catch (error) {
        var e = error.response
        var message

        if (e.status === 401) {
          message = e.data
        } else {
          var errors = Object.values(e.data.errors).flat()
          message = errors[0]
        }
        Notify.create({
          message,
          icon: 'lock',
          timeout: 5000,
          color: 'negative',
          textcolor: '#fff'
        })
      }
      this.processing = false
      this.$q.loading.hide()
    },
    navTo (name) {
      if (this.$route.name !== name) {
        this.$router.replace({ name })
      }
    },
    ...mapActions('users', [])
  },
  components: {
    QField,
    QInput,
    QBtn,
    QCard,
    QCardSection
  }
}
</script>

<style>
.submitLogin {
  background-image: linear-gradient(270deg, #3ccdef 0%, #1c9edb 100%);
}
.my-text {
  color: #3f3356;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
#registerLink {
  color: #1c9edb;
  font-weight: 700;
  text-decoration: underline;
}
</style>
